<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="إضافة عملة">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الكود</label>
                <b-form-input v-model="code" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
     

            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="en_name"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم باللغة الانجليزية</label>
                <b-form-input v-model="en_name" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
           
            <b-col md="4" xl="3">
              <b-button variant="purple" @click="addUnit">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة عملة</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" العملات">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
            <!-- <label>بحث</label> -->
            <div>
              <b-form-input
                v-model="search"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      العدد الكلي </label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-currency',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deleteCurrency(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import useBillersList from "./List";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,

  },
setup(){
  const {
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useBillersList()
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }

},
  data() {
    return {
      name: "",
      code:'',
      brand_name:'',
      optioneItemCategory:[],
      en_name: "",
      item_category_id:'',
      
      columns: [
      {
          label: " الاسم",
          field: "name",
        },
        {
          label: "الكود ",
          field: "code",
        },
        
        {
          label: "لاسم باللغة الانجليزية",
          field: "en_name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    // this.getCurrency()

  },

  methods: {
   
    deleteCurrency(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/currency/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            // this.getCurrency()
            this.refetchData();

            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
    },
    addUnit() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            
                data = {
        en_name: this.en_name,
        code: this.code,
        name:this.name,
       
      };
          
      this.$http.post("/api/v1/currency", data).then((res) => {
        //console.log(res);
        this.name=""
        this.code=""
        this.en_name=""
        requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
        // this.getCurrency()
        this.refetchData();

        this.$swal({
          title: "",
          text: "تمت أضافة العملة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getCurrency() {
      this.$http.get("/api/v1/currency").then((res) => {
        //console.log('rewards', res)
        this.rows = res.data;
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>